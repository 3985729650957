import React from "react";
import "./App.css";
import Banner from "./Banner";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <nav className="App-nav">
        <div className="logo" style={{ display: 'flex', alignItems: 'center' }}><img src="/assets/logo/dungbeetle.gif" alt="MyBrand Logo" style={{ maxWidth: '140px' }} /></div>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </header>
      <main>
        <section id="home" className="hero-section">
          <Banner />
        </section>
        <section id="services" className="services-section">
          <div className="services-content">
            <h2>My Services</h2>
            <div className="services">
              <div className="service-item">
                <h3>Hair Style Transformer</h3>
                <h6>Date: 24.09 ~ 24.12</h6>
                <span>A journey to find my hair styles that suits me.<br/> 💇‍♂️💇‍♀️<br/> </span>
                <button className="cta-button">More</button>
              </div>
              <div className="service-item">
                <h3>Coming sooon</h3>
                <h6>Date: 25.01 ~ 25.07</h6>
                <p>Contents will be filled ASAP.</p>
              </div>
              <div className="service-item">
                <h3>Coming sooon</h3>
                <h6>Date: 25.08 ~ 25.12</h6>
                <p>Contents will be filled ASAP.</p>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="about-section">
        <div className="about-me-container">
          <h3 className="about-me-title">About Me</h3>
          <p className="about-me-content">
            I'm a solopreneur driven by passion and purpose. My journey is filled with ideas and projects aimed at making a meaningful impact on people's lives. <br/> I believe in creating positive change, one idea at a time, to inspire, connect, and uplift others.
          </p>
        </div>
        </section>
        <section id="contact" className="contact-section">
          <div className="contact-content">
            <h2>Contact Information</h2>
            <p>Email:hiseok3736@gmail.com</p>
          </div>
        </section>
      </main>
      <footer className="App-footer">
        <div className="footer-content">
          <p>&copy; 2024 IAM_NOT_AI. All rights reserved.</p>
          <nav className="footer-nav">
            <ul>
              <li><a href="#privacy">Privacy Policy</a></li>
              <li><a href="#terms">Terms of Service</a></li>
            </ul>
          </nav>
        </div>
      </footer>
    </div>
  );
}

export default App;
