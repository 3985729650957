// Banner.js
import React from "react";
import "./Banner.css"; // 스타일은 따로 정의

const Banner = () => {
  return (
    <div className="banner">
      <img 
        src="/banner.jpg" // 배너 이미지 경로
        alt="Portfolio Banner" 
        className="banner-image" 
      />
    </div>
  );
};

export default Banner;
